import React, { useState, useEffect, useMemo } from "react";
import { Admin, Resource } from "react-admin";
import firebase from "firebase/app";
import "firebase/auth";
import { FirebaseAuthProvider } from "react-admin-firebase";
import hasuraDataProvider from "ra-data-hasura";
import { Route } from "react-router-dom";

import polyglotI18nProvider from "ra-i18n-polyglot";
import ArabicMessages from "./lang/ar";
import { create } from "jss";
import rtl from "jss-rtl";
import {
  createMuiTheme,
  StylesProvider,
  jssPreset,
} from "@material-ui/core/styles";
import { AccountCircle, Category, Toc, Edit, Code } from "@material-ui/icons";
import "./App.css";

import Dashboard from "./Dashboard";
import { UserCreate, UserList } from "./users/UserControl";
import {
  SoftwareCreate,
  SoftwareEdit,
  SoftwareList,
} from "./software/SoftwareControl";
import { TopicCreate, TopicEdit, TopicList } from "./topics/TopicControl";
import { CodeCreate, CodeEdit, CodeList } from "./codes/CodeControl";
import {
  ArticleCreate,
  ArticleEdit,
  ArticleList,
  ArticleShow,
} from "./articles/ArticleControl";
import { PasswordChange } from "./users/PasswordChange";
const firebaseConfig = {
  apiKey: "AIzaSyCFAixDL8EWDjrjInSLky-TP5NhPOHbLlQ",
  authDomain: "dsactivation-8cb3a.firebaseapp.com",
  databaseURL: "https://dsactivation-8cb3a.firebaseio.com",
  projectId: "dsactivation-8cb3a",
  storageBucket: "dsactivation-8cb3a.appspot.com",
  messagingSenderId: "145173366152",
  appId: "1:145173366152:web:980260fc1c60e1178ee63d",
  measurementId: "G-EW6H3QWFZV",
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const authProvider = FirebaseAuthProvider(firebaseConfig, { app: firebase });
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createMuiTheme({
  direction: "rtl",
  palette: {
    primary: {
      main: "#ff4400",
    },
    secondary: {
      main: "#0044ff",
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: "Tajawal",
  },
});

const i18nProvider = polyglotI18nProvider(
  () => ArabicMessages,
  "ar" // Default locale
);

function App() {
  const [authToken, setAuthToken] = useState(null);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim =
          idTokenResult.claims["https://hasura.io/jwt/claims"];
        if (hasuraClaim) {
          setAuthToken(token);
        } else {
          setAuthToken(null);
        }
      } else {
        setAuthToken(null);
      }
    });
  }, []);

  const headers = useMemo(() => {
    if (authToken === null) {
      return {
        "content-type": "application/json",
      };
    } else {
      return {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      };
    }
  }, [authToken]);

  return (
    <StylesProvider jss={jss}>
      <Admin
        dashboard={Dashboard}
        dataProvider={hasuraDataProvider("https://api.dsluxor.com", headers)}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        theme={theme}
        title="Mau Tours Admin"
        customRoutes={[
          <Route
            key="change-password"
            path="/change-password"
            component={PasswordChange}
          />,
        ]}
      >
        <Resource
          icon={Category}
          name="software"
          list={SoftwareList}
          create={SoftwareCreate}
          edit={SoftwareEdit}
          options={{ label: "البرامج" }}
        />
        <Resource
          icon={Code}
          name="activation_codes"
          list={CodeList}
          create={CodeCreate}
          edit={CodeEdit}
          options={{ label: "أكواد التفعيل" }}
        />
        <Resource
          icon={Toc}
          name="topics"
          list={TopicList}
          create={TopicCreate}
          edit={TopicEdit}
          options={{ label: "الموضوعات" }}
        />
        <Resource
          icon={Edit}
          name="articles"
          list={ArticleList}
          create={ArticleCreate}
          edit={ArticleEdit}
          show={ArticleShow}
          options={{ label: "المقالات" }}
        />
        <Resource
          icon={AccountCircle}
          name="users"
          list={UserList}
          create={UserCreate}
          options={{ label: "المستخدمين" }}
        />
        <Resource name="article_software" />
      </Admin>
    </StylesProvider>
  );
}

export default App;

import React, { useState } from "react";
import { useNotify } from "ra-core";
import firebase from "firebase/app";
import "firebase/functions";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";

export const PasswordChange = (props) => {
  const notify = useNotify();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const handleSave = async (e) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      var user = firebase.auth().currentUser;
      await user.updatePassword(password);
      notify("ra.notification.updated", "info", {
        smart_count: 1,
      });
    } catch (e) {
      console.log(e);
      notify(e.message, "error");
    }
  };

  const isValid = password && password === passwordConfirm;

  return (
    <form onSubmit={handleSave}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            تغيير كلمة السر
          </Typography>
          <TextField
            variant="filled"
            type="password"
            label="كلمة السر"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <br />
          <TextField
            variant="filled"
            type="password"
            label="تأكيد كلمة السر"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </CardContent>
        <CardActions>
          <Button
            type="submit"
            disabled={!isValid}
            variant="contained"
            color="primary"
          >
            تغيير
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

import React from "react";
import { Create, SimpleForm, TextInput, Edit } from "react-admin";
import { List, Datagrid, TextField } from "react-admin";

export const TopicList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="title" label="عنوان" />
    </Datagrid>
  </List>
);

export const TopicEdit = (props) => (
  <Edit {...props}>
    <TopicForm />
  </Edit>
);

export const TopicCreate = (props) => (
  <Create {...props}>
    <TopicForm />
  </Create>
);

const TopicForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="title" label="عنوان" />
  </SimpleForm>
);

import React, { useCallback } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  SaveButton,
  Toolbar,
  useRedirect,
  useNotify,
} from "react-admin";
import {
  List,
  Datagrid,
  TextField,
  required,
  minLength,
  email,
} from "react-admin";
import firebase from "firebase/app";
import "firebase/functions";

export const UserList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
    </Datagrid>
  </List>
);

const validatetName = [required()];
const validateEmail = [required(), email()];
const validatePassword = [required(), minLength(6)];

const SaveUserButton = (props) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const { basePath } = props;
  const handleSave = useCallback(
    async (values, redirect) => {
      const registerModerator = await firebase
        .functions()
        .httpsCallable("registerModerator");
      const result = await registerModerator(values);
      notify("ra.notification.created", "info", {
        smart_count: 1,
      });
      redirectTo(redirect, basePath, result.data.id, result.data);
    },
    [notify, redirectTo, basePath]
  );
  // set onSave props instead of handleSubmitWithRedirect
  return <SaveButton {...props} onSave={handleSave} />;
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveUserButton />
  </Toolbar>
);

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="name" validate={validatetName} />
      <TextInput source="email" type="email" validate={validateEmail} />
      <PasswordInput source="password" validate={validatePassword} />
    </SimpleForm>
  </Create>
);

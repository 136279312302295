import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  Edit,
  SelectField,
  DateField,
} from "react-admin";
import { List, Datagrid, TextField } from "react-admin";
import { CodeTextInput } from "../components/CodeField";

const timeChoices = [
  { id: 2592000000, name: "شهر" },
  { id: 31536000000, name: "سنة" },
];

export const CodeList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="customer" label="العميل" />
      <TextField source="code" label="الكود" />
      <SelectField source="time" label="المدة" choices={timeChoices} />
      <DateField
        locales="en-GB"
        source="first_use"
        label="تاريخ أول استخدام"
        showTime
      />
      <DateField
        locales="en-GB"
        source="last_use"
        label="تاريخ أخر استخدام"
        showTime
      />
    </Datagrid>
  </List>
);

export const CodeEdit = (props) => (
  <Edit {...props}>
    <CodeForm />
  </Edit>
);

export const CodeCreate = (props) => (
  <Create {...props}>
    <CodeForm />
  </Create>
);

const CodeForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="customer" label="العميل" />
    <SelectInput source="time" label="المدة" choices={timeChoices} />
    <CodeTextInput source="code" label="الكود" />
  </SimpleForm>
);

import React from "react";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { useInput } from "ra-core";
import { Code } from "@material-ui/icons";

export const CodeTextInput = (props) => {
  const {
    input: { name, onChange, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const handleGenerate = () => {
    const code =
      getRandomChars(4) +
      "-" +
      getRandomChars(4) +
      "-" +
      getRandomChars(4) +
      "-" +
      getRandomChars(4);
    onChange(code);
  };

  const getRandomChars = (length) => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  return (
    <TextField
      name={name}
      label={props.label}
      onChange={onChange}
      value={value}
      disabled
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
      variant="filled"
      margin="dense"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleGenerate} disabled={Boolean(value)}>
              <Code />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

import * as React from "react";
import { Link } from "react-admin";
import Logo from "./logo.png";
import firebase from "firebase/app";

export default function Dashboard() {
  const user = firebase.auth().currentUser;

  return (
    <div className="dashboard">
      <img src={Logo} alt="Filuxor Logo" title="logo" />
      <h2>Welcome, {user && user.displayName}</h2>
      <Link to="/change-password">Change Password</Link>
    </div>
  );
}

import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ShowButton,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  TabbedShowLayout,
  RichTextField,
  ImageField,
  ReferenceManyField,
  DeleteButton,
  useCreate,
  useNotify,
  useRefresh,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  Tab,
  List,
  Datagrid,
  TextField,
} from "react-admin";
import { Form, Field } from "react-final-form";
import { Box, Button, Grid } from "@material-ui/core";
import RichTextInput from "ra-input-rich-text";
import firebase from "firebase/app";

export const ArticleList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="title" label="عنوان" />
      <ReferenceField source="topic_id" reference="topics" label="موضوع">
        <TextField source="title" />
      </ReferenceField>
      <ShowButton />
    </Datagrid>
  </List>
);

export const ArticleEdit = (props) => (
  <Edit {...props}>
    <ArticleForm />
  </Edit>
);

export const ArticleCreate = (props) => (
  <Create {...props}>
    <ArticleForm />
  </Create>
);

const ArticleForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="title" label="العنوان" />
    <ReferenceInput label="الموضوع" source="topic_id" reference="topics">
      <SelectInput optionText="title" />
    </ReferenceInput>
    <ImageUpload source="image" />
    <RichTextInput source="content" label="المحتوي" />
  </SimpleForm>
);

export const ArticleShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="title" label="عنوان" />
          <ReferenceField source="topic_id" reference="topics" label="موضوع">
            <TextField source="title" />
          </ReferenceField>
          <ImageField source="image" label="صورة" />
          <RichTextField source="content" label="محتوي" />
        </Tab>
        <Tab label="البرامج" path="software">
          <SoftwareConnect article_id={parseInt(props.id)} />
          <ReferenceManyField
            {...props}
            reference="article_software"
            target="article_id"
          >
            <Datagrid>
              <ReferenceField source="software_id" reference="software">
                <TextField source="name" />
              </ReferenceField>
              <DeleteButton redirect={false} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

export const SoftwareConnect = ({ article_id }) => {
  // const [create, { loading }] = useCreate("city_tour");
  const [create] = useCreate("article_software");
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async (values) => {
    // console.log({ values });
    if (values.software.length === 0) return;
    for (let i = 0; i < values.software.length; i++) {
      create(
        {
          payload: {
            data: { article_id, software_id: values.software[i] },
          },
        },
        {
          onSuccess: ({ data }) => {
            // notify("ra.notification.created");
            refresh();
          },
          onFailure: ({ error }) => {
            notify(error.message, "error");
          },
        }
      );
    }
  };

  return (
    <Form
      initialValues={{ article_id }}
      onSubmit={onSubmit}
      subscription={defaultSubscription} // don't redraw entire form each time one field changes
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item sm>
              <ReferenceArrayInput
                label="البرامج"
                reference="software"
                source="software"
                resource="article_software"
              >
                <SelectArrayInput fullWidth>
                  <ChipField source="name" />
                </SelectArrayInput>
              </ReferenceArrayInput>
            </Grid>
            <Grid item sm="auto">
              <Box pt={2}>
                <Button color="primary" variant="contained" type="submit">
                  أضف
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const ImageUpload = ({ source }) => {
  const onUpload = async (e, onFinish) => {
    const files = e.target.files;
    if (files.length === 0) return;
    const storageRef = firebase.storage().ref();
    const file = files[0];
    const filename = Date.now();
    const imageRef = storageRef.child("articles/" + filename);
    try {
      const snapshot = await imageRef.put(file);
      const file_url = await snapshot.ref.getDownloadURL();
      onFinish(file_url);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Field name={source}>
      {(props) => {
        return (
          <div>
            <label>
              الصورة
              <br />
              <input
                type="file"
                multiple
                accept=".png,.jpg,.jpeg"
                onChange={(e) => onUpload(e, props.input.onChange)}
              />
              <br />
              {props.input.value && (
                <img src={props.input.value} alt="" width={256} height="auto" />
              )}
            </label>
          </div>
        );
      }}
    </Field>
  );
};

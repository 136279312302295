import React from "react";
import { Create, SimpleForm, TextInput, Edit } from "react-admin";
import { List, Datagrid, TextField } from "react-admin";

export const SoftwareList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="الاسم" />
    </Datagrid>
  </List>
);

export const SoftwareEdit = (props) => (
  <Edit {...props}>
    <SoftwareForm />
  </Edit>
);

export const SoftwareCreate = (props) => (
  <Create {...props}>
    <SoftwareForm />
  </Create>
);

const SoftwareForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="name" label="الاسم" />
    <TextInput source="slug" label="عنوان" />
  </SimpleForm>
);
